import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import ru from "./locales/ru.json";
import de from "./locales/de.json";

const messages = {
  en,
  ru,
  de,
};

const browserLocale = navigator.language.split("-")[0];

const currentLocale = messages[browserLocale] ? browserLocale : "en";

const i18n = createI18n({
  locale: currentLocale,
  fallbackLocale: "en",
  messages,
});

const app = createApp(App);
app.use(i18n);
app.mount("#app");
