<template>
  <div>
    <div v-if="errors.length" class="errors">
      <h3>{{ $t("message.errorbox_title") }}</h3>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>
    <div class="form">
      <label for="username" class="form-label">{{
        $t("message.form_username_label")
      }}</label>
      <input v-model="username" type="text" id="username" class="form-text" />
      <small class="form-description">{{
        $t("message.form_username_description")
      }}</small>
      <a
        :href="mumbleLink"
        class="button-link"
        :class="{ disabled: !mumbleLink }"
        >{{ $t("message.form_button_label") }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MumbleLinkForm",
  data() {
    return {
      username: "",
      password: "",
      errors: [],
    };
  },
  computed: {
    mumbleLink() {
      if (this.username && this.password) {
        return `mumble://${this.username}:${this.password}@mumble.legionofdeath.space`;
      }
      return "";
    },
  },
  created() {
    this.decodePassword();
  },
  mounted() {
    document.title = this.$t("message.page_title");
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.$t("message.page_description"));
  },
  methods: {
    decodePassword() {
      const params = new URLSearchParams(window.location.search);
      const encodedPassword = params.get("password");
      if (encodedPassword) {
        try {
          this.password = atob(encodedPassword);
        } catch (e) {
          this.errors.push(
            this.$t("message.error_password_decoding", { error: e })
          );
        }
      } else {
        this.errors.push(
          this.$t("message.error_password_empty", { parameter: "password" })
        );
      }
    },
  },
};
</script>

<style>
.form-text {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

.form-text:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button-link {
  margin-top: 1.25rem;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
}

.button-link:hover {
  color: #fff;
  background-color: #0056b3;
  border-color: #0056b3;
  text-decoration: none;
}

.button-link:focus,
.button-link.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}

.button-link.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.button-link:not(.disabled):active,
.button-link:not(.disabled).active {
  color: #fff;
  background-color: #0056b3;
  border-color: #0056b3;
}

.button-link:not(.disabled):active:focus,
.button-link:not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.form-description {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.875em;
  color: #6c757d;
}

.errors {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  color: #842029;
  border-radius: 0.25rem;
}

.errors > h3 {
  margin: 0;
}

.errors > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.errors ul > li {
  margin: 0.25rem 0;
}
</style>
